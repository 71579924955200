<template>
  <div class="container">
    <div class="row mb-3 margin-top-0421">
      <div class="col-sm-12">
        <img
          src="/imgc/blog/3/header.webp"
          class="img-fluid rounded w-100 img-thumbnail"
          alt="Having a flight companion means more than just company"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h1 class="text-primary">
          India Announces ‘Air Bubbles’ With US, France; Flights have started
        </h1>
        <small>
          <i class="fa fa-clock text-secondary"></i> 01 May, 2021 |
          <i class="fa fa-user text-secondary"></i> Admin
        </small>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-sm-12 text-justify">
        In a major development in India’s civil aviation sector, Union Aviation Minister Hardeep
        Puri has announced that India has established ‘air bubbles’ with France and the US. <br />

        Negotiations with the UK and Germany are underway. While addressing a press conference, the
        aviation minister said, "Initially the air bubbles are with countries which have made a
        request. Till the part of the international civil aviation can reclaim its pre-COVID
        numbers, I think the answer will lie through bilateral air bubbles which will carry a
        possible number of people but under defined conditions."<br />

        This means that international air travel is only possible when there are no restrictions in
        other countries.<br />

        An air bubble is a travel corridor between two countries that will fly their aircraft in a
        regulated environment. This arrangement has been made due to several travel restrictions
        across the globe because of the Covid-19 pandemic. Explaining the concept of ‘air bubbles
        Secretary, Ministry of Civil Aviation Pradeep Singh Kharola, said, "There are three
        preconditions, there should be demand, India should have permission to bring passengers back
        and take passengers to a particular country & the carrier should be willing to fly. It is a
        regulated movement."<br />

        Air Travel in India, both the domestic and international, was suspended when the national
        lockdown started on the 25th of March due to the Covid-19 pandemic. Only domestic air travel
        began on the 25th of May. This decision to establish air bubbles was taken after a few
        countries which include the US, accused Air India of unfair & discriminatory practices last
        month. US Transportation Department said that the Indian Government had prevented US
        carriers from operating chartered flights on the Indo-US routes even though Air India was
        doing it with the help of a Vande Bharat Mission. As of 15th of July, India has brought back
        687467 Indian under Vande Bharat Mission which includes 215495 by national carrier Air
        India, Phase 4 of Vande Bharat Mission is underway with private airlines also participating
        in this mission to bring back stranded Indian from other parts of the world. So far, 12258
        Indians have been brought back by private airlines in India.<br />

        <i
          >Aviation Minister Hardeep Puri also mentioned, "We have got a request from Germans also,
          the arrangement with Lufthansa is almost done. We are getting many demands for air bubbles
          but will allow the amount that we can handle." Soon similar air bubbles are expected to be
          established with Germany & UK too. A senior management official in the Ministry of Civil
          Aviation said, "talks on air bubble with the UK is in an advanced stage."</i
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Blog3',
  created() {
    document.title = 'Trepr - India Announces ‘Air Bubbles’ With US, France; Flights have started';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'In a major development in India’s civil aviation sector, Union Aviation Minister Hardeep Puri has announced that India has established ‘air bubbles’ with France and the US.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,India Announces Air Bubbles With US, France; Flights have started, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
};
</script>
